import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";

const KampanieZagraniczne = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "International Marketing Campaigns",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/kampanie-zagraniczne/",
      }}
    >
      <section className="container-fluid campaign_section">
        <div className="text-center">
          <h1>International Marketing Campaigns</h1>
        </div>
        <div className="row campaign_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Are you expanding your business and planning to conquer foreign
              markets? Or maybe you have been running your businesses in other
              areas for a long time and are thinking about intensifying your
              activities?
            </p>
            <p className="text-center">
              International marketing campaigns are one of the best ways to use
              the company's area of ​​operation and reach the choice of your
              customers not only in Europe, but almost all over the world. An
              in-depth analysis guarantees a thorough check of the potential of
              a specific market before starting any activities, thanks to which
              they are targeted at the most absorbent and profitable country.
            </p>
            <p className="text-center">
              Find out where in the world we have already showed our ads:
            </p>
          </div>
        </div>
        <div className="row campaign_row2">
          <div className="col-md-9 m-auto">
            <div className="text-center">
              <img
                className="img-fluid"
                src={require("../../../assets/img/offer/campagne_map.svg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row campaign_row2">
          <div className="col-md-9 m-auto">
            <p className="text-center">
              We carry out the campaigns through various channels: Google Ads,
              Facebook Ads, LinkedIn Ads, Seznam (Sklik). Depending on your
              goal, we select the right channel and develop sales messages in
              cooperation with native speakers, so that you can be sure that the
              language we use is correct. The campaigns are supervised by our
              teams: SEM (Search Engine Marketing) and Social Media, and our
              certified specialists are always happy to conquer any new markets!
            </p>
          </div>
        </div>
      </section>
      <section className="container-fluid case_study_section">
        <h2>Case study</h2>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <Link
              to="/case-study/kampania-adwords-dla-branzy-turystycznej"
              className="portfolio_tile case"
            >
              <h3 className="case">
                Ad words campaign <br /> for the tourism industry
              </h3>
              <div
                className="portfolio_tile-back case"
                style={{
                  backgroundImage: `url(${require("../../../assets/img/offer/case_study_turystyczna_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../../../assets/img/offer/case_study_turystyczna_front.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="col-lg-4 col-md-6">
            <Link
              to="/case-study/matrace-market"
              className="portfolio_tile case"
            >
              <h3 className="case">Matrace Market</h3>
              <div
                className="portfolio_tile-back case"
                style={{
                  backgroundImage: `url(${require("../../../assets/img/offer/case_study_matrace_back.png")})`,
                }}
              ></div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6">
            <Link to="/case-study/bananki" className="portfolio_tile case">
              <h3 className="case">Bananki</h3>
              <div
                className="portfolio_tile-back case"
                style={{
                  backgroundImage: `url(${require("../../../assets/img/offer/case_study_bananki_back.png")})`,
                }}
              ></div>
              <img
                className="img-fluid"
                src={require("../../../assets/img/offer/case_study_bananki_front.png")}
                alt=""
              />
            </Link>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Web Analytics</span>
            <Link to="/en/offer/web-analytics/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/en/offer/social-media/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/linkedin-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/en/offer/facebook-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Banner Ads</span>
            <Link to="/en/offer/banner-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google ads</span>
            <Link to="/en/offer/google-ads">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default KampanieZagraniczne;
